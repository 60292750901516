import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/elves-poster.jpg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1989 Christmas horror movie, Elves"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 25</h1>
                    <h2>Elves</h2>
                    <h3>November 13, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <h1>Transcript</h1>
                        <p><Link to="/episodes/elves">Back to the episode details</Link></p>
                        <div>
                        <p>Bryan! (00:01.964)
Hey, you&#39;re listening to Bring Me the Axe. I&#39;m Brian White and one half of this morbid equation and I&#39;m joined by my co-host and actual brother, Dave White. Hey Dave, how you doing?</p>
<p>Dave! (00:10.03)
Oh boy, I&#39;ll tell you what I&#39;ve done today. We took down the Halloween decorations and it&#39;s a it&#39;s a sad time and I mean I&#39;m a I&#39;m not a terribly festive person just in general. I&#39;ve been trying to be more festive lately um but it&#39;s like it&#39;s it signals the entrée to uh official winter.</p>
<p>Bryan! (00:17.178)
Oh man, that sucks.</p>
<p>Dave! (00:35.738)
And I&#39;ll tell you what happens to me is every year winter gets harder and harder for me as I am an aging man.</p>
<p>Bryan! (00:42.596)
Yeah. Yeah, I, you know, I like the first sort of, you know, the first like week or so of just like winter proper and then I&#39;m immediately done with it. Just it, it&#39;s always cold. It&#39;s always cold in my house no matter where we have the thermostat, whatever. It&#39;s just, it&#39;s brutal. But for that first week, I&#39;m just like, ah, this is all right. I can do it. I can do this. And then I&#39;m immediately like by the end of December, I&#39;m like, fuck this. What am I doing here?</p>
<p>Dave! (01:09.302)
It does not make a lot of sense. And I live in a place where it&#39;s a lot of renters who come and go very quickly, and you have to shovel your own sidewalks here, because it&#39;s a city, and I&#39;ll tell you what, not everybody shovels their own sidewalks here.</p>
<p>Bryan! (01:23.852)
I would imagine like you&#39;re really rolling the dice with that sort of thing. Uh, people, yeah. Yeah. Cause like even, even the fact that like most of the shit is taken care of for us around here, you know, we pay a fee for that. Uh, and still it&#39;s a real mixed bag of conditions out of the neighborhood.</p>
<p>Dave! (01:41.142)
Well, I&#39;m gonna tell you what, the takeaway is this. Get off my lawn, shovel your sidewalks, and keep your shitty kids quiet when they&#39;re walking by my apartment.</p>
<p>Bryan! (01:45.36)
I&#39;m gonna go.</p>
<p>Bryan! (01:51.612)
Oh, listen to you. Yep. That&#39;s, that&#39;s, that&#39;s the city of equivalent of a stay off my lawn.</p>
<p>Dave! (01:53.014)
That&#39;s my life now.</p>
<p>Dave! (01:58.326)
Yep, and I blame this on elves.</p>
<p>Bryan! (02:02.988)
Yeah, we could we could really we could really use the elves as a scapegoat for many things going forward because this is a real this is a real humdinger of a movie. So uh yeah so we practically grew up in neighborhood video stores and the steady diet of utter garbage that those shops provided us with continues unabated to this day. There&#39;s no one else I enjoy chopping it up with more about trashy movies than Dave.</p>
<p>Dave! (02:12.876)
Hey.</p>
<p>Bryan! (02:25.412)
Just before we get into it, it&#39;s a little housekeeping. If you want to keep up with us between episodes, you can find us pretty much on Instagram these days. Really kind of narrowed it down. Bring Me the Axe Horror Podcast on Instagram. And you can find Dave over there at That Queer Wolf. We&#39;re having a good time. We got a sweet website now at bringmetheeaxe.com. You can listen to all our past shows there and read the transcripts. You can also contact us directly at bringmetheeaxepod at gmail.com with any questions, comments, or suggestions.</p>
<p>Dave! (02:39.871)
Mm-hmm.</p>
<p>Bryan! (02:53.592)
Do let us know if there&#39;s a movie that you love and would like to hear us give it the business. Lastly, if you like what you hear, you can subscribe to us wherever you get your podcasts and you&#39;d be doing us a favor by leaving us a five-star review on Apple podcasts and you can also do that on Spotify and I implore you to do so.</p>
<p>Dave! (03:06.99)
You know, even if you don&#39;t like what you hear, just fucking just rate it. I don&#39;t care if you like it or not.</p>
<p>Bryan! (03:11.212)
Right? That&#39;s a solid point. Like who gives a fuck? Just five star it. Do it up.</p>
<p>Dave! (03:17.253)
Do me this solid, please. I ask this of you.</p>
<p>Bryan! (03:19.66)
Yeah. We asked so little of you. Just, you know, throw us a bone.</p>
<p>Dave! (03:25.546)
Yeah, shovel your fucking sidewalks and rape my goddamn podcast!</p>
<p>Bryan! (03:32.776)
So, I just wanna get all that out of the way right at the top of the show. So, let&#39;s give you a little taste. Let&#39;s give you a little taste of what we&#39;re gonna talk about here.</p>
<p>Dave! (03:38.19)
Show this fucking movie!</p>
<p>Bryan! (05:29.2)
Hahaha!</p>
<p>Dave! (05:30.339)
Ugh, this movie is such a piece of shit.</p>
<p>Bryan! (05:33.232)
Ha ha!</p>
<p>Dave! (05:35.594)
Right up, right up top, this movie does- has nothing to do with elves. It doesn&#39;t have to take place at Christmas, it just does. It&#39;s not Santa Claus, it&#39;s just Dan Haggerty and a fucking Santa Claus. This is horrible. This movie is horrible.</p>
<p>Bryan! (05:36.816)
Ha ha!</p>
<p>Dave! (05:51.798)
Damn it!</p>
<p>Bryan! (05:52.408)
Oh, oh, dude, you have no idea how long I&#39;ve been looking forward to this one. I am so excited because this movie fucking sucks. But it&#39;s such a great movie at the same time, because it is a fuckfest of epic proportions. Like it is irredeemably bad. And the story is preposterous. Every.</p>
<p>Dave! (06:14.686)
Oh, it is so tasteless. This movie is just awful.</p>
<p>Bryan! (06:19.914)
If you had to pitch this movie to somebody, how would you do it?</p>
<p>Dave! (06:26.066)
I think you already did it when you said it&#39;s Jim Winozki&#39;s Gremlins because that is 100% what this- the whole time watching it I&#39;m like, why does this feel like an even shittier version of Chopping Mall? But with like really cheap gremlins in it.</p>
<p>Bryan! (06:30.777)
Yeah, that&#39;s...</p>
<p>Bryan! (06:41.696)
Yeah, so this is the thing. Okay, so let&#39;s do the facts and then we&#39;ll get into it. So this is the year is 1989. So some other movies released. Yeah, let&#39;s go down that list. So Leviathan came out that year. Yep, Nightmare on Elm Street 5, The Dream Child, ostensibly the worst in the series.</p>
<p>Dave! (06:51.014)
Real terrible year for film.</p>
<p>Dave! (06:57.642)
Yeah.</p>
<p>Dave! (07:05.144)
Okay.</p>
<p>Bryan! (07:07.823)
Puppet Master, which I love Puppet Master. I can&#39;t say shit about that. Also Shocker came out that year and I fucking I love Shocker as well. And rounding it out, Brian Usna&#39;s Society.</p>
<p>Dave! (07:09.366)
Man.</p>
<p>Dave! (07:20.894)
OK, that one&#39;s pretty good.</p>
<p>Bryan! (07:22.7)
Yep, yep. So, you know, it&#39;s a mixed bag, but elves and the top three on that list are doing 1989 no favors. Yeah, cast and crew. So the director is a guy named Jeff Mandel, who was a writer. Me either until I looked him up writer of a RoboCop style action show for syndication called Super Force that I actually used to watch.</p>
<p>Dave! (07:39.275)
Never heard of him.</p>
<p>Dave! (07:48.674)
He also made a movie, I&#39;m gonna guess it&#39;s called Super Cyber Chick, but it&#39;s cause it&#39;s, yeah, it&#39;s C-H-I-C, you know, like a, what the fuck is that called? Yeah, an acronym. And yeah, I was just like, I&#39;m choosing to believe it, it&#39;s called Cyber Chic.</p>
<p>Bryan! (07:54.444)
Yeah, but it&#39;s spelled Sheik.</p>
<p>Bryan! (08:03.028)
uh... an acronym and an abbreviation</p>
<p>Bryan! (08:09.84)
I&#39;m a cyber chic. Yeah. You were waiting to deliver that one, huh? Hot damn. You&#39;re good with the improv. So, yeah, I don&#39;t remember much of that show except that the hero seems to die in the final episode of the series. Uh he also</p>
<p>Dave! (08:11.798)
Yes, le freak cyber chic.</p>
<p>Dave! (08:17.856)
Actually that one just came to me.</p>
<p>Dave! (08:30.311)
He also wrote something called O&#39;Hara, starring Pat Morita, and I actually kind of remember.</p>
<p>Bryan! (08:36.232)
I don&#39;t. Was it? Wait, was he&#39;s a cop in it?</p>
<p>Dave! (08:39.114)
Yeah, he&#39;s like a detective in, I don&#39;t know, California or Hawaii, wherever they were like, hmm, where is an Asian guy gonna be a cop? It was probably that.</p>
<p>Bryan! (08:46.632)
Yeah, yeah. Yeah, he was he wrote more than he directed. And what he directed is mostly just like sleazy t&amp;a direct to video stuff. That&#39;s, that&#39;s it. And this falls squarely in the center of that milieu.</p>
<p>Dave! (09:03.842)
But the other guy who wrote this, Bruce Taylor, he wrote that show Dead at 21, do you remember that? I think it was on MTV.</p>
<p>Bryan! (09:06.713)
Yeah.</p>
<p>Bryan! (09:10.312)
I do. Yeah, with Jack Noseworthy. Yeah. Also, for some reason, there are three writers credits on this fucking movie.</p>
<p>Dave! (09:13.751)
Yep.</p>
<p>Dave! (09:21.446)
Yeah, but one of them is like a guy who didn&#39;t... this is the only thing he did. So I&#39;m gonna guess that. Probably. That&#39;s probably it for him.</p>
<p>Bryan! (09:25.842)
Oh, yeah.</p>
<p>Bryan! (09:31.436)
Yeah, he was like, hey, why don&#39;t we add like, why don&#39;t we add a whole rape subtext to this thing? And they were like, fucking, hey, give that guy a screen credit.</p>
<p>Dave! (09:38.894)
And they&#39;re like, alright, thanks Steve, get out.</p>
<p>Bryan! (09:41.832)
Yep. So cast, let&#39;s see. We got Dan Grizzly Adams Haggerty. Yep, who chains smokes through this entire picture. I swear they paid him in cigarettes in this movie. Because</p>
<p>Dave! (09:48.051)
We got Grizzly Adams himself.</p>
<p>Dave! (09:57.218)
So I looked, when I looked him up though, so obviously for people who don&#39;t know, Dan Haggerty was Grizzly Adams, Grizzly Adams was, I don&#39;t even think it was long running. I think it was kind of a short run.</p>
<p>Bryan! (10:06.304)
It was like two seasons, two seasons in the 70s.</p>
<p>Dave! (10:09.922)
But there were a bunch of movies. And he&#39;s just a guy who lives in the woods. Yeah, it&#39;s basically that. And it&#39;s basically, it&#39;s like, what if the Incredible Hulk didn&#39;t have a superhero? He was just a guy who lived in the woods. It&#39;s kind of like that. But he was doing those movies until 1995. Yeah.</p>
<p>Bryan! (10:15.47)
as all his friends are animals.</p>
<p>Bryan! (10:26.348)
Yeah.</p>
<p>Bryan! (10:31.216)
I was like, holy <em>**</em> Yeah, I I kind of looked him up because I was like, I don&#39;t really know much about Dan Haggerty apart from this whole this whole whole thing. I saw that he died 2016, I think of cancer and I was like, oh, well, obviously based on this movie, lung cancer. No, it was like spinal cancer but he he</p>
<p>Dave! (10:47.094)
Yeah, presumably lung cancer.</p>
<p>Bryan! (10:56.664)
he was like a motorcycle enthusiast and his life is just like riddled with catastrophic motorcycle accidents like one that put him in a coma another that killed one of his wives like it&#39;s a real fucking crazy</p>
<p>Dave! (11:08.41)
Hey, he just kept getting back on, and that&#39;s really what matters.</p>
<p>Bryan! (11:12.088)
You can&#39;t really let you can&#39;t really let tragedy get you down, man. You got to love what you love.</p>
<p>Dave! (11:15.722)
Yeah, yeah. Dan Haggerty, when you fall down and your wife dies and you&#39;re in a coma, you just gotta get up, you gotta dust yourself off, and you gotta get back on that fucking motorcycle and do it again, hopefully without a new wife.</p>
<p>Bryan! (11:28.396)
Yep. I know and he also did a little time for selling coke to a cop. Yeah. Whomst among us?</p>
<p>Dave! (11:34.798)
Yeah, I mean, ooh, among us. He was also in a movie that I really love called Terror Out of the Sky. It&#39;s a TV movie from the 70s about killer bees. It&#39;s actually the sequel to a killer bees movie.</p>
<p>Bryan! (11:46.13)
Oh, yes. How many Killer B&#39;s movies are there? There&#39;s got to be a shitload of them.</p>
<p>Dave! (11:51.106)
There are a bunch. I mean, off the top of my head, I can think of three. No, I can think of four, actually. You got Terra in the sky. You&#39;ve got the bees with John Saxon. You&#39;ve got the swarm with Olivia de Havilland. I think it&#39;s Olivia de Havilland. I think so.</p>
<p>Bryan! (11:58.456)
Yeah, it&#39;s crazy. The bees, the swarm. Yeah. What&#39;s what&#39;s the one with what&#39;s the one with Michael Caine?</p>
<p>Dave! (12:09.154)
That&#39;s, I think that&#39;s the swarm. Or no, that&#39;s, no, that was a theatrical.</p>
<p>Bryan! (12:10.873)
Okay.</p>
<p>Bryan! (12:14.296)
The De- The Deadly Bees?</p>
<p>Dave! (12:17.902)
can&#39;t remember.</p>
<p>Bryan! (12:18.86)
I don&#39;t know. But let&#39;s see. Also coming up in this is we got nobody else of note really. Not really. Nobody else. So there&#39;s a there&#39;s a girl named Julie Austin who shows up. She&#39;s our lead. She shows up in a few steamy erotic DTV thrillers and then she seems to vanish. Yep. Yeah, yeah. And then there&#39;s Deanna Lund who plays Austin&#39;s psychotic mother.</p>
<p>Dave! (12:24.77)
Nope, nobody you&#39;ve ever heard.</p>
<p>Dave! (12:34.21)
that Extreme Justice. She&#39;s a star of Extreme Justice, which sounds very sexy.</p>
<p>Bryan! (12:46.044)
The Wikipedia article for her is significantly longer than you&#39;d expect it to be. And it&#39;s worded in order to give you an impression that she is a very important actress. Like there is a pretty exhaustive breakdown of her life. The only thing that I know about her...</p>
<p>Dave! (13:00.118)
Yeah, here&#39;s the thing. TLDR, she&#39;s not. She was on one television show.</p>
<p>Bryan! (13:03.928)
No. Yeah, she was on uh land of the giants and got her start in uh Doctor Goodfold, Doctor Goldfoot and the Bikini Machine with Vincent Price. A fucking great movie.</p>
<p>Dave! (13:14.306)
which is an incredible film.</p>
<p>Now, the sequel is of questionable value, though it was directed by Mario Buf.</p>
<p>Bryan! (13:22.509)
Yeah.</p>
<p>Yeah. So there&#39;s not a lot to know about this movie. The people who made it weren&#39;t terribly prolific. They never worked on anything else that was even remotely interesting. For the most part, everybody involved made cheap TNA movies for the home video market. And then each of their movies is cast with nobodies except that they always seem to manage to land one like minor league TV figure like</p>
<p>Dave! (13:26.826)
Well, sort of.</p>
<p>Dave! (13:53.602)
Are you talking about the Ameri- what is it? Action International Pictures? The distributor? So here&#39;s what I know about Action International Pictures. That it is partially owned by David A. Pryor. And the only thing I know about David A. Pryor was that he directed Killer Workout. One of two! One of two fitness-based horror films from the 80s. Yup. Yeah, so that&#39;s all I know about him.</p>
<p>Bryan! (13:57.618)
Isyap</p>
<p>Bryan! (14:08.994)
Own.</p>
<p>Bryan! (14:13.212)
Oh yeah, death spy. Yep, that&#39;s great, man. Yeah.</p>
<p>Dave! (14:21.234)
Also, this movie came out at the end of October, which is a great time to release a Christmas-themed film.</p>
<p>Bryan! (14:26.472)
perfect timing yeah just right in right on right on time the let&#39;s see like some of the</p>
<p>Dave! (14:31.607)
I&#39;ve poured all of her money into pumpkins. I think they&#39;re gonna peak right around January.</p>
<p>Bryan! (14:40.54)
Oh yeah. And also this movie&#39;s this movie obviously was made on like 50 bucks. I guarantee $40 went to the construction of the puppet like this the elf in this movie. The elf okay, so the movie is called elves, but let&#39;s just kind of let&#39;s just clear something up here. There is one elf in this movie.</p>
<p>Dave! (14:52.51)
And that&#39;s being very generous.</p>
<p>Dave! (15:02.794)
And to call it an elf is a bit of a stretch.</p>
<p>Bryan! (15:05.824)
Yeah, as a matter of fact, it goes by a bunch of names. The elf is like the real world, but everybody&#39;s like it&#39;s a troll. It&#39;s a ninja thing.</p>
<p>Dave! (15:13.258)
Now do you think they were gonna call it Troll and then they were like, shit, there&#39;s already a movie called Troll, what do we do? I have an idea, put Grizzly Adams in a Santa Claus outfit, we&#39;ll call it elves.</p>
<p>Bryan! (15:20.573)
I don&#39;t-</p>
<p>Bryan! (15:25.268)
Yeah, you know what the thing is I guarantee that this was like one of those movies that was pitched with a poster and they were like it&#39;ll it will do it for it&#39;ll be a Christmas thing and it&#39;ll be about like Santa&#39;s elves gone awry and then by the time that it got into production, it was like Nazis and uh the master race and this homunculus thing and like this movie kind of</p>
<p>Dave! (15:45.802)
Oh yeah, no, this movie, I&#39;m gonna say the first 40% of it, they were taking it seriously, and then they got to that point and were like, Jesus Christ, this movie is ridiculous. Let&#39;s just, everybody lean in as hard as you can. Let&#39;s just get this shit over with. Now, I think probably what happened was they had a tagline instead, and the tagline, there are two. The first one is, twas the night before Christmas, and all through the town, bloodthirsty elves are about to get down. First of all,</p>
<p>Bryan! (15:56.198)
Ha ha.</p>
<p>Dave! (16:15.598)
FUCK YOU FOR THAT TAG LINE, THAT IS TERRIBLE!</p>
<p>Bryan! (16:20.432)
I&#39;m not gonna say that is awesome. That is awesome, awesome. But I want I want to know like what I mean, obviously, it&#39;s budget. Budget was holding them back. But like, come on. We we goo like we got ghoulies.</p>
<p>Dave! (16:23.746)
God!</p>
<p>Dave! (16:38.294)
No.</p>
<p>Dave! (16:45.934)
It&#39;s all they can afford.</p>
<p>Dave! (16:50.37)
Okay.</p>
<p>Bryan! (16:51.832)
with pointy teeth, but his face is always the same expression and it looks it is like his head is kind of tilted up and his mouth is always open like he just got kicked in the balls.</p>
<p>Dave! (17:01.718)
Yeah, cause this is not a puppet. This is like a rubber mask. It&#39;s like the end of a sleepaway camp when her mouth is just like, she&#39;s like Angela&#39;s frozen in this like horrific snarl. It&#39;s like that. It&#39;s like whenever the elf shows up, it&#39;s just someone with like a stick just shaking it at somebody else. Cause it just like moves back and forth or side to side. It just, this is not a mobile thing.</p>
<p>Bryan! (17:09.304)
Hahaha!</p>
<p>Bryan! (17:19.183)
Yeah.</p>
<p>Right, right. So, and it&#39;s definitely not a single piece either because it&#39;s either the sort of chest up to the head or the legs or an arm and you never see a full body shot of the elf. It&#39;s always just like the legs like yeah. Yeah.</p>
<p>Dave! (17:37.87)
Yeah, because it doesn&#39;t have a body. It&#39;s just a head. It&#39;s just a torso, like a bust of a, I guess an elf, I don&#39;t know.</p>
<p>Bryan! (17:45.46)
Yeah, so the other tagline for this movie is they&#39;re not working for Santa anymore. But I&#39;d argue I&#39;d argue that the elf never really worked for Santa in the first place, because as we find out, he&#39;s been working for Hitler.</p>
<p>Dave! (17:51.844)
God, fuck these people.</p>
<p>Dave! (17:55.894)
Yeah, no.</p>
<p>Dave! (17:59.69)
Yeah, Santa was a real, like I&#39;m telling you, Christmas was a real add-on at the end, where they were like, I don&#39;t know, what are we gonna do? Christmas, I guess? Because there&#39;s not a lot of Christmas horror movies. Actually, there are, but you&#39;ve never heard of three quarters of them, and they&#39;re terrible.</p>
<p>Bryan! (18:07.042)
Yeah.</p>
<p>Bryan! (18:13.574)
Ha ha.</p>
<p>Yeah, yeah, this is this is one of them. So, yeah, this Terravision released a soundtrack, I think last year it came out. And this is kind of how this is kind of like how everybody came back around to it, like anybody who&#39;s talking about it these days. It&#39;s probably because of that. The soundtrack.</p>
<p>Dave! (18:23.742)
Okay. That&#39;s right there, bud.</p>
<p>Dave! (18:36.074)
Yeah, because we should say this movie is not available on DVD at all.</p>
<p>Bryan! (18:40.32)
No, and to say well, there&#39;s supposedly so yeah, like I got this off of a fucking torrent and it claims to be off of a DVD rip. But if I got a DVD that looks like this, I would have been furious because it is essentially a videotape. And like a shitty videotape. It&#39;s probably this probably the same release that I&#39;ve got. It&#39;s like the one that it&#39;s the one that streams through Sarah and it&#39;s the one that.</p>
<p>Dave! (18:55.894)
Yeah, I watched this on YouTube. There was on YouTube... It was probably the same one because... Yeah.</p>
<p>Bryan! (19:07.406)
that&#39;s on YouTube. It&#39;s the one that you can get off of archive.org.</p>
<p>Dave! (19:10.978)
Yeah, there&#39;s like hard tracking on it in a bunch of different places.</p>
<p>Bryan! (19:14.988)
Yeah. Yep. It&#39;s a forgotten movie that nobody is allowing to disappear fully down the memory hole. Oh, yeah. So my initial sort of thought on this movie, and this occurred to me the very first time I watched it earlier this year, was every single scene in this movie looks like a reenactment from Unsolved Mysteries. Everything&#39;s...</p>
<p>Dave! (19:38.818)
Yeah, my first note, my first note says this movie is cheap as fuck.</p>
<p>Bryan! (19:44.242)
Everything is over lit. And it&#39;s bizarre. There&#39;s like zero artistry to it. It&#39;s such a weird fucking movie.</p>
<p>Dave! (19:52.146)
It&#39;s over- yeah, it&#39;s overlit except when it&#39;s not. It&#39;s dark as hell. I cannot see a thing in this movie.</p>
<p>Bryan! (19:56.888)
When it&#39;s dark, like when this movie starts, it is, you are squinting. Get the biggest TV possible, it doesn&#39;t matter. You will have no idea what is happening.</p>
<p>So let&#39;s dive right in. So this movie opens on three grown ass women in the woods. There&#39;s Kirsten, our lead, and her two friends, Amy and Brooke, who are performing a ceremony dedicated to anti-Christmas in the cold ass woods.</p>
<p>Dave! (20:23.83)
Now, if you watch the trailer for this, the announcer voice, which is a real true television announcer voice, he refers to it as a romp in the woods. And I thought, when was the last time you heard someone say a romp in the woods? Or a romp anywhere for that matter.</p>
<p>Bryan! (20:31.597)
Yeah.</p>
<p>Bryan! (20:41.685)
Yeah, you would often hear about movies that were a mad cap romp and I that&#39;s a descriptor that&#39;s dynamite top to bottom. Yeah. So, Kirsten hates Christmas and basically everything else and we&#39;re gonna find out why in a few minutes when we meet her psycho family but it yeah so that&#39;s what I&#39;m what I&#39;m getting into is</p>
<p>Dave! (20:45.364)
Mm-hmm.</p>
<p>Dave! (21:00.086)
but they perform, you gotta talk about the ritual because as they&#39;re starting to do it, and she goes, we have to be close to Mother Earth, and one of them goes, get real, Kristen, and then the other one goes, get over it, Amy. That&#39;s about as good as the movie gets.</p>
<p>Bryan! (21:15.196)
Yeah, they do a lot of like way, no way way, no way kind of stuff throughout this movie. It&#39;s like yeah, chemistry. Though in the process of this cemetery, the cemetery, the ceremony, one of the girls reveals a drawing of their symbol and it&#39;s elaborate drawing of a naked woman with a cup in one hand and a crystal in the other and some lines drawn over her boobs.</p>
<p>Dave! (21:21.798)
Yeah.</p>
<p>Bryan! (21:41.96)
So you&#39;re gonna wanna put a pin in that. Just remember those. Remember those lines for later. And so she says, I drew those art deco boobs. Cool, huh?</p>
<p>Dave! (21:44.138)
Yeah, yeah, grandpa&#39;s gonna yes and that later.</p>
<p>Dave! (21:57.456)
Jesus Christ.</p>
<p>Bryan! (21:59.549)
Shh. Sure. That&#39;s cool. Yeah. So at a certain point, crea- Ha ha ha.</p>
<p>Dave! (22:01.624)
Oh no!</p>
<p>Dave! (22:12.332)
Oh god.</p>
<p>Bryan! (22:12.408)
At a certain point, Kirsten drops the candle and the glass around it breaks and she cuts her hand bleeding onto the ground causing the ground to undulate and then a slimy little rubber hand rises out of the crowd just they&#39;re all like walking away.</p>
<p>Dave! (22:28.086)
The best part of this too is when it&#39;s, cause I think it&#39;s Amy who&#39;s like, she thinks they&#39;re there to get her like a boyfriend or something. And she&#39;s like,</p>
<p>Bryan! (22:36.648)
Amy is obsessed with a guy named Dave.</p>
<p>Dave! (22:41.182)
And she goes, you gotta, something about help me get Dave, and one of them goes, his is bent anyways.</p>
<p>Bryan! (22:47.492)
that&#39;s the thing. There is we here is the thing we learn we learn nothing about any of these girls including our lead but we&#39;re going to learn an awful lot about Dave&#39;s penis. You know, just through their conversation throughout it because this is not the last time we hear about Dave and I suspect that</p>
<p>Dave! (22:49.986)
There is so much dick talk in this movie, it is like a 12-year-old boy wrote it.</p>
<p>Dave! (23:03.739)
Ah, Jesus.</p>
<p>Bryan! (23:11.988)
And we&#39;ll talk about it at a certain point, but I think he&#39;s the dead body that falls through the door when the Nazis bust into the department store.</p>
<p>Dave! (23:19.854)
I couldn&#39;t tell because it&#39;s so fucking dark that you cannot see who&#39;s who and what is going on in this protracted shootout scene that is coming later.</p>
<p>Bryan! (23:25.648)
Ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha</p>
<p>Bryan! (23:30.54)
Yep. So we get a little elf POV footage as it follows Kirsten home. And she gets into the place, she carefully replaces this book that she took out of the to out to their ceremony in her grandfather&#39;s library. And then we meet her grandfather who immediately slaps her in the face. Yep, at grandpa.</p>
<p>Dave! (23:50.646)
Now the grandfather is incredible because he is... have you ever seen that movie Bees, the one I was just talking about with John Saxon? With John Carradine is in it and he plays basically the same character where he&#39;s like, what is this? And it&#39;s like, oh Jesus Christ, you are really, you&#39;re not even trying.</p>
<p>Bryan! (23:57.589)
Yeah</p>
<p>Bryan! (24:03.784)
Yeah. Oh, yeah. Grandfather is clearly your he&#39;s your stock, scary German guy, but he&#39;s clearly a middle aged dude in a lot of old guy makeup. And for some reason, he wheels around the entire movie, you know, in a wheelchair from like 1915, like the sort of thing that you would convalescent or like</p>
<p>Dave! (24:26.326)
Yeah, it&#39;s like the one in the wheelchair from the changeling.</p>
<p>Bryan! (24:29.396)
Yeah, yes. Yeah. He is a he&#39;s a real trip. But then we meet her mother whose first lines are, Are you hurt? Good. This is just the beginning. This woman</p>
<p>Dave! (24:39.598)
Good.</p>
<p>Dave! (24:43.142)
And they never really, so they sort of explain the mother is like a sadistic psychopath throughout the entire movie. And they don&#39;t really explain it. They kind of do later on, like at the end of the movie, but not fully. It&#39;s still just like, okay, I get why she doesn&#39;t like the girl, but it still doesn&#39;t explain why she&#39;s such a fucking asshole through the whole movie.</p>
<p>Bryan! (24:53.668)
They get around to it. Yeah.</p>
<p>Bryan! (25:03.104)
She&#39;s a s- this house is the sort of house that produces serial killers. Like, i- Oh my god, yes, this is 100% like V.C. Andrews shit. Because like, I guarantee that the unmade sequel to this movie is how her little brother grew up to be like a serial murderer.</p>
<p>Dave! (25:08.546)
God, no, this is like flowers in the attic.</p>
<p>Bryan! (25:28.62)
Yep. So this woman is about to do some really dark shit. For the moment, Mom is punishing Kirsten for going into her grandfather&#39;s library without his permission by closing out her savings account and taking all of her money.</p>
<p>Dave! (25:42.282)
And she says, you can&#39;t do that. Grandpa&#39;s already slapped me twice.</p>
<p>Bryan! (25:48.951)
The dialogue in this movie is solid gold in places.</p>
<p>Dave! (25:53.538)
Yeah, it&#39;s like I wrote it today.</p>
<p>Bryan! (25:57.367)
Yeah, and then so now Kirsten goes to take a shower and afterwards her creep ass little brother snoops on her while she&#39;s drying off.</p>
<p>Dave! (26:05.814)
Now, to be clear, this kid is like eight years old.</p>
<p>Bryan! (26:09.024)
Yep. And it&#39;s not really clear how old Kirsten is supposed to be. She&#39;s like supposed to be a teenager, I guess, but she doesn&#39;t really look like a teenager. I don&#39;t want to, I don&#39;t want to get too deep into that. But her brother is a lot younger. And given</p>
<p>Dave! (26:17.291)
Yeah, sure.</p>
<p>Dave! (26:26.75)
And his incestuous desire is really intense and I just, I did not care for it at all.</p>
<p>Bryan! (26:33.528)
Nope. No, and given what we learn about Kirsten&#39;s father a little later on, who fathered this kid? The same guy?</p>
<p>Dave! (26:43.486)
You know, you can&#39;t ask questions like that because you&#39;re never going to get answers. And whatever you get, it&#39;s not going to be satisfactory.</p>
<p>Bryan! (26:47.084)
No, no, no</p>
<p>Dave! (27:06.486)
Yeah, cause he&#39;s just a fucking gross creep. He&#39;s like, he&#39;s like, was it Jonathan, was it Jonathan Miller? The kid from Near Dark and something Miller, Jason Miller? He&#39;s Jason Miller&#39;s son. But he reminds me, he reminds me of this kid, like the kid from Teen Witch and Near Dark, the little kid, the one who plays Homer in Near Dark. He&#39;s like that, like the kid in, the character he plays in Teen Witch.</p>
<p>Bryan! (27:18.38)
Um no, no. What what I re maybe what I remember. Oh really.</p>
<p>Bryan! (27:31.17)
Oh yeah, yeah.</p>
<p>Dave! (27:35.862)
where he&#39;s just kind of this like weird unhinged psychopath who just screams like offensive shit at his sister all the time. Except this kid also wants to have sex with her.</p>
<p>Bryan! (27:47.104)
Yeah. You know what he reminded me of is in um yeah. God is it night of the demons. I get it confused. It&#39;s either night of the demons or it&#39;s return of the living dead part two where the girl is getting changed. I think it&#39;s night of the demons and her brother bursts out of the out of the closet. And he and he and he goes bodacious boobs sis.</p>
<p>Dave! (27:48.68)
It is deeply troubling.</p>
<p>Dave! (28:04.723)
No, that is Return of the Living Dead.</p>
<p>Dave! (28:09.91)
Yep, that&#39;s the, that is Night of the Living Dead, or Return of the Living Dead, part two.</p>
<p>Bryan! (28:13.588)
Yeah. No, no good. It&#39;s no good. It&#39;s not a funny joke. Not funny.</p>
<p>Dave! (28:17.942)
Yeah, cause this kid basically he&#39;s just like, I saw your boobs and they&#39;re great and I like boobs and I&#39;m gonna tell all my friends. It&#39;s like, tell all your friends what? That you&#39;re an incestuous creep? Was that, that wasn&#39;t cool. I was eight years old in 1989. That shit wasn&#39;t cool then.</p>
<p>Bryan! (28:27.941)
Oh, I know. It&#39;s poof.</p>
<p>Bryan! (28:35.376)
thought never crossed my mind. Nope. So next we meet Kirsten&#39;s cat Agamemnon who is actually a lady cat and also pregnant. So keep that in mind and don&#39;t get too comfortable with Agamemnon. Nope. So that night the elf breaks into the house and attacks Kirsten&#39;s brother before it&#39;s chased off by the cat. And so the little</p>
<p>Dave! (28:58.006)
Now, but in the lead up to that, you get tons of that, a more killer POV. But here&#39;s the thing about killer POV, it really only works if it&#39;s looking at something significant. This is just like if a guy was taking a walk.</p>
<p>Bryan! (29:03.353)
Yeah.</p>
<p>Bryan! (29:12.576)
Yeah, it&#39;s like it&#39;s moving around through the house in the dark, but also it has this weird like misty. Got a trippy effect applied to it when he whenever he runs around. So whatever he&#39;s running around the environment is just completely indiscernible. That&#39;s not going to improve. So the elf breaks in attacks kid cat chases it off and the kid says it&#39;s a little ninja troll.</p>
<p>and then it slashes him before it gets away and then evil mom blames the cat. You know, cuz she&#39;s evil. Yep. Uh so now we cut to the next morning at Gollum&#39;s department store where we meet Detective Grizzly Adams, cigarette in hand, and also</p>
<p>Dave! (29:47.902)
Listen, everybody in this movie sucks.</p>
<p>Dave! (30:00.77)
But that&#39;s before, so before we meet him, he&#39;s walking by a brass band that&#39;s playing Christmas music, and it is very clearly Christmas music that was recorded with like a MIDI player, except they&#39;re wanting us to believe that it is a brass band playing it.</p>
<p>Bryan! (30:11.299)
Yeah.</p>
<p>Bryan! (30:15.168)
It&#39;s synthesized brass, but like Casio SK-1 class synthesizer music. Like it&#39;s very, very bad. Yep. So he&#39;s a yep. So he goes to the place cigarette in hand. You are going to see a cigarette in his hand throughout the entire movie. There is not a moment when he&#39;s not smoking. As a matter of fact, there&#39;s a real key sort of climax moment later on that involves him lighting a cigarette in his car.</p>
<p>it is the fucking funniest running joke and I gotta I guarantee like they were like okay so here&#39;s you know here&#39;s a contract we&#39;re gonna you know pay this you&#39;ll be on set for this many days and you know no stunts will have a stunt man and he&#39;s like okay can I smoke and they&#39;re like I sure he&#39;s like okay well I get to smoke as much as I want and they&#39;re like fine he&#39;s like why don&#39;t you throw in a couple of carts of cigars</p>
<p>Dave! (31:04.522)
Because they were like, how much can one grizzly Adams really smoke? Well, joke&#39;s on you, Action International Pictures!</p>
<p>Bryan! (31:09.244)
He&#39;s gonna he&#39;s gonna he&#39;s gonna surprise you. So, the girls from the night before are now at the soda counter which is the thing that department stores used to have and they&#39;re complaining</p>
<p>Dave! (31:20.534)
Alright, listen, here&#39;s what this place reminded me of. In the Fox Run mall, next to Dream Machine, which was the arcade, next to that, there was a buffet restaurant. Do you remember that restaurant?</p>
<p>Bryan! (31:32.418)
Oh shit, yeah, York Castle.</p>
<p>Dave! (31:34.845)
Yes, that is what it reminded me of.</p>
<p>Bryan! (31:37.592)
Oh, man. Now that you mention it. Yes. God, that was a hell of a burger.</p>
<p>Hahaha</p>
<p>Dave! (31:45.902)
I mean, it&#39;s a buffet at the mall, so, okay, sure.</p>
<p>Bryan! (31:48.22)
Yep. But this was this was a ball in like 1987. So like, it&#39;s like peak mall season. Yeah, so this there now they&#39;re complaining about Kirsten&#39;s mom. And we learned that she had Kirsten when she was 16. So keep that in mind for a little later on, because things are going to get really, really gross.</p>
<p>Dave! (32:09.994)
Yeah, actually don&#39;t. You don&#39;t have to keep any of this in mind. They really just spell this shit out right in front of you every ten minutes.</p>
<p>Bryan! (32:17.388)
Oh, no, but this that reveal. You could tell that was a late addition to the story. Like like you said, they got to the point where they were like they got done with this with the big shootout scene and they were like, well, I thought we had a whole script here and they were like, surprise, we don&#39;t. So now what? And they&#39;re like, OK, well. Yeah, yeah. So also the elf follows her to work. And then the girls, they take a break to go fuck with them all, Santa.</p>
<p>Dave! (32:35.406)
But we have grizzly atoms for two more days.</p>
<p>Bryan! (32:46.984)
And now we kind of cut away from them for a little bit. And we find out that Grizzly Adams, he used to be the store detective in this place, is back in the store after 90 days at AA looking for a job.</p>
<p>Dave! (32:56.718)
I thought he was like an actual d-</p>
<p>Bryan! (32:59.5)
he was and then he drank his way out of the job. I think is like one of the detectives who talks to him later on was like you are one of the best we ever had. You drank your way out of that and then you drank your way out of a two bit store detective gig or something like that.</p>
<p>Dave! (33:12.47)
Wow, he really just sort of like climbed down the ladder. He didn&#39;t really fall off. He just sort of climbed down and then got off.</p>
<p>Bryan! (33:18.388)
Yep. So also, he see he always has this look on his face throughout the entire movie. And I had to put it I had to make a note about this because it&#39;s very, very funny. It&#39;s this kind of like furrowed brow expression that kind of makes it look like he sat on his keys. He seems to be in pain throughout this entire movie.</p>
<p>Dave! (33:40.354)
Yeah, I think the way I described him was he looks like he has to take a shit the entire movie.</p>
<p>Bryan! (33:49.176)
It&#39;s a mess. But the thing is, his affect is so mellow, so just completely threw out it. And the fact that</p>
<p>Dave! (33:56.054)
Yeah, he has got one speed and that&#39;s it. He&#39;s like, I&#39;m going to give you, this is the Grizzly Adams experience. I&#39;m going to give it to you just like this. My character smokes all the time. He is constipated a lot. And he doesn&#39;t really want to be here, but he also has nowhere else to go. So fuck it. That is the character. Oh, and he used to be a cop.</p>
<p>Bryan! (34:15.596)
Yeah, oh, he&#39;s... It&#39;s the best. It&#39;s this, his character, when I saw him, I was like, Grizzly Adam&#39;s in this movie? And then as I went on, I started to just love him more and more because he never ever changes his, like you said, he&#39;s got one speed and he never wavers from it. And it never, it&#39;s not intense. He&#39;s not, he never gets excited. He&#39;s always just at an even keel.</p>
<p>throughout the entire movie.</p>
<p>Dave! (34:46.082)
Yeah, no, listen, I&#39;m not gonna lie. There is something alluring about Grizzly Addams.</p>
<p>Bryan! (34:51.04)
It&#39;s the fucking beard. His beard is amazing.</p>
<p>Dave! (34:54.306)
Like I look at this guy and I&#39;m like, I don&#39;t know, maybe.</p>
<p>Bryan! (34:57.66)
I would, I could hang out with Grizzly Adams. As long as he brought his bear with him. I don&#39;t necessarily wanna hang out with him. I just wanna pet the animals. His bear was Ben, yeah. He rescued it when it was just a cub. And they grew up and became his best buddy.</p>
<p>Dave! (35:04.586)
Was this Bear Ben?</p>
<p>Bryan! (35:14.152)
So when it&#39;s Kirsten&#39;s time to sit on Santa&#39;s lap, he makes a pass at her, to put it lightly.</p>
<p>Dave! (35:23.401)
He basically sexually assaults her.</p>
<p>Bryan! (35:23.504)
because yeah, like he kind of like he puts his hand up her skirt. Basically, he says this is like I want to blow job and so she punches him.</p>
<p>And then we cut away from this. We go back home and evil mom is casually throwing Agamemnon the cat into a bag and then happily drowns it in the toilet. Yeah. So we cut. Yeah, and then she and she buries it and she makes some comment. She&#39;s like, well, at least I&#39;m gonna get some fertilizer out of all for all this trouble. It&#39;s like lady. Take it down a notch. We already know that you&#39;re evil.</p>
<p>Dave! (35:46.85)
Yeah, that&#39;s like a thing you do.</p>
<p>Dave! (35:59.77)
My note says, I hope the elves kill them all.</p>
<p>Bryan! (36:04.553)
the elves. Well, they get they do get around to most of the people. Yeah. The elf. Yeah. So, Santa gets fired and then the elf follows him back to his dressing room where he cuts out like a hundred bucks worth of blow on a mirror.</p>
<p>Dave! (36:08.162)
I mean, elf, I guess, like, you just won.</p>
<p>Dave! (36:19.766)
Look, there is nothing more 80s than fucking cutting up and railing lines off of a pocket mirror.</p>
<p>Bryan! (36:25.524)
Hahaha!</p>
<p>Bryan! (36:29.676)
Yep. And then he scatters it accidentally with his Santa Claus beard. While he&#39;s distracted. The elf pops up and stabs him repeatedly in the dick. And this is how he dies.</p>
<p>Dave! (36:44.694)
Because remember, this movie is 60% dick jokes.</p>
<p>Bryan! (36:52.601)
the cops show up to deal the body, one of them can be seen in the background like wiping his finger on the countertop where the coke was and then like rubbing it on his gums. So she goes, Kirsten goes home to tell her mother that she had a really rough day at work because Santa got murdered. Casual remark. And then</p>
<p>Dave! (37:13.686)
And her mother says, I don&#39;t think that&#39;s very funny.</p>
<p>Bryan! (37:16.664)
Yep. I don&#39;t think she could have said anything that her mother would have liked though. It sounds like her mother, she must sleep with one eye open basically. So, Grizzly Adams, meanwhile, gets home to his trailer remarking that the surgeon general says that a pack a day will keep your lungs nice and gray, that he finds out that he&#39;s been evicted.</p>
<p>Dave! (37:38.614)
Now listen, this trailer, I don&#39;t, it looks like it&#39;s made of cardboard. Like, it looks like it&#39;s like a prop.</p>
<p>Bryan! (37:44.672)
It does. It doesn&#39;t really look. It doesn&#39;t look like a trailer. It doesn&#39;t look like a trailer I&#39;ve ever seen.</p>
<p>Dave! (37:50.506)
No, it&#39;s made of plywood and cardboard. It looks like someone spray painted plywood and cardboard and like hot glued them together.</p>
<p>Bryan! (37:58.892)
Um is it&#39;s I think it&#39;s snowing in that scene too. I think they must have done it on a sound stage and like built it like they couldn&#39;t they couldn&#39;t find a trailer to shoot at or something like I</p>
<p>Dave! (38:10.21)
They were out of money. That money, that was Grizzly Adam&#39;s money, and they were out of money. I also felt like they were ready to cut to commercial any minute.</p>
<p>Bryan! (38:15.481)
He was like, yeah, sure. Come back to my place.</p>
<p>Bryan! (38:23.094)
There are definitely a bunch of moments throughout this movie that almost looked like they were designed for a commercial cut, like to be on television.</p>
<p>Dave! (38:30.634)
Yeah, because they were like, we know what we&#39;re doing. We know where this one&#39;s going to end up.</p>
<p>Bryan! (38:35.54)
Yep. So like I said, it&#39;s just like Unsolved Mysteries reenactments all the way to the end. So back at Kirsten&#39;s house, the elf digs up the dead cat that evil mom buried earlier that day. And then Kirsten is her room calling for the cat. And then she thinks that she hears it. But when she gets up to look out the window, she sees the elf instead furiously spearing the cat&#39;s corpse on the window.</p>
<p>And it looks it&#39;s one of those times where the elf&#39;s face is never expressive in any way whatsoever. But it does kind of kind of looks it&#39;s frozen in terror like a scream that it can&#39;t believe.</p>
<p>Dave! (39:11.678)
It&#39;s frozen.</p>
<p>Dave! (39:18.658)
but also a little bit of glee as well.</p>
<p>Bryan! (39:20.94)
But yeah, like this is the part where I, it looks like he&#39;s laughing maniacally while it&#39;s doing this. Because I think some of the ways that they kind of fake emotion with the puppet is they kind of like jiggle it a little bit. Like there&#39;s a part a little later on where he&#39;ll tip a radio into a bath. Oh, God, yes. They, he tips the radio into a bathtub and then sort of like rocks back and forth like very gleefully.</p>
<p>Dave! (39:39.434)
Oh, that was my favorite. This is my favorite part.</p>
<p>Dave! (39:49.378)
Like, he&#39;s supposed to be laughing.</p>
<p>Bryan! (39:51.14)
Yep. Face unchanging either that or he&#39;s rocking out.</p>
<p>Dave! (39:54.882)
I loved it, whatever he was doing, I loved it.</p>
<p>Bryan! (39:57.805)
Yeah. So again, she wakes everybody up and they go downstairs and evil mom wants to know what all the commotion was about to which Kirsten tells her it was a troll. Grandfather excitedly wants to hear all about it and also wants to know where she saw the lady with the art deco boobs that she drew in the notebook.</p>
<p>So when Kirsten leaves, he excitedly announces to evil mom that the elf is here, and then she tells him that she&#39;s the one who killed the cat.</p>
<p>Dave! (40:26.702)
It&#39;s apropos of nothing.</p>
<p>Bryan! (40:28.51)
just nope. So, the next day, she meets Detective Grizzly Adams at work over a cup of coffee and a carton of smokes.</p>
<p>Dave! (40:34.434)
This is the bleakest shit in this movie. He has got a fucking carton of camels. He is just pounding cheap coffee. Chrisley Adams at this point is like 70% acid refluxes. That&#39;s what&#39;s keeping him together.</p>
<p>Bryan! (40:49.324)
Yeah, these are also these are also unfiltered camels because there&#39;s a couple of there&#39;s one spot where he pulls the cigarette out of his out like out of the pack and he puts it directly into his mouth without turning it around. And I was like, holy shit, that dude is bold.</p>
<p>Dave! (41:05.174)
Yeah, that is Dan Haggarty&#39;s daily rate.</p>
<p>Bryan! (41:08.012)
Yep. Just a pack of smokes. Two probably. Maybe three. So, his, the store manager shows up desperately in need of a new Santa and he offers the, he offered it on the spot to Grizzly Adams. So, while on break, back at the scene of the crime for some reason, Grizzly Adams finds a rune carved into the floor that looks an awful lot like the blue oyster cult logo.</p>
<p>And he draws it and then he&#39;s gonna become obsessed with it later on. But back at home, so this movie basically cuts back and forth between two locations, the department store and home. So there&#39;s gonna be a lot of that. Back at.</p>
<p>Dave! (41:50.33)
And each transition will be marked with some real kind of like Columbo murder. She wrote incidental music.</p>
<p>Bryan! (41:56.788)
Yep. Back at home, grandfather is visited by more scary German guys who are also very interested in the elf. The new scary German guy tells him when there is no more room in hell, the elves will walk the earth. Oh, yeah, I know. Come on. Oh, so. But we come this is a call back to that line later on.</p>
<p>Dave! (42:10.312)
Oh, oh no you did not.</p>
<p>Dave! (42:15.35)
You cheap motherfuckers.</p>
<p>Also, this movie feels like a feature length episode of Passions. Like it is just, it is so shitty in every way. The lighting is fucking terrible. The acting is awful. The writing is like, it&#39;s non-existent. Like this has to be improv.</p>
<p>Bryan! (42:36.972)
It was written, it was either improvised or written on the set that day.</p>
<p>What we learn from these new scary German guys is that Kirsten is some kind of pure genetic line, but grandfather wants her left out of whatever plans they have for her. We&#39;ll find out about that later. You know, we&#39;ll come back to it back at work. Kirsten is having another one of those nights when we see one of the scary German guys watching her and her friends show up because they have some plans for the night, which are going to roll out shortly.</p>
<p>Dave! (42:43.681)
Ugh.</p>
<p>Bryan! (43:09.152)
And meanwhile, Amy lifts up her sweater to ask what the others think of her red bra, to which they reply, no way, Dave hates red.</p>
<p>Dave! (43:18.49)
I do not, so okay, this movie was very obviously written by a man, I&#39;m gonna guess a man probably in his 20s. Remember when we were talking, we were watching Halloween and one of the things about Halloween that is remarkable is that when it came to writing dialogue for young women, John Carpenter had the good sense to say, I&#39;m gonna ask a woman to do this. Nobody in this movie bothered to ask a woman anything and I doubt they ever have because.</p>
<p>Bryan! (43:38.273)
Yeah.</p>
<p>Bryan! (43:43.861)
Oh, no, if the thought ever.</p>
<p>Dave! (43:44.354)
This is non-stop dick jokes and terrible things about like, Hey, what do you think of my tits? Are they too big? No, they can&#39;t be big enough! What the fuck?</p>
<p>Bryan! (43:53.547)
Yeah. No, no. If the thought ever occurred, like, maybe I should have a woman write this, he would have been like, what am I thinking? Yeah.</p>
<p>Dave! (44:00.65)
women? Well, next I&#39;m just gonna have to ask him to drive me somewhere. God, get out of here, ya fag! Because trust me, there&#39;s a little bit of that in there too.</p>
<p>Dave! (44:16.179)
Jesus.</p>
<p>Bryan! (44:24.806)
the</p>
<p>Dave! (44:26.614)
Yeah, cause his life just keeps getting bleaker.</p>
<p>Bryan! (44:31.976)
I get the feeling that there was there when they wrote this when they shot it they were like oh man this is gonna be funny as hell but there is a wicked nihilism to this movie that just yeah I could not really get around it&#39;s there&#39;s a darkness to it that is entirely</p>
<p>Dave! (44:43.902)
oh no, this movie is cynical as fuck</p>
<p>Dave! (44:56.802)
Yeah, this is two steps up from bum fights. Like this is really, they would have made more money making pornography, but there probably wasn&#39;t enough creative freedom.</p>
<p>Bryan! (45:01.216)
Hahaha</p>
<p>Bryan! (45:07.28)
the He goes down to the library to do a little research about that</p>
<p>Dave! (45:15.958)
No, no, this is when he says, I remember a book from college. It&#39;s like, first of all, you should not go to college. But also, even if you did, how do you remember a book about runes from college? But that&#39;s not even the best fucking part. The best part is the IMDB goofs section on this movie, it only has but one goof in it. And it is this scene. And I&#39;m going to read it to you right now. It goes, when Mike McGavin asks the librarian about books on the occult.</p>
<p>Bryan! (45:22.545)
Hahaha!</p>
<p>Bryan! (45:40.366)
Okay.</p>
<p>Dave! (45:45.322)
he is referred to section 666. In an American library, which uses Dewey Decimal classification, books on parapsychology and the supernatural would be reserved in 130. 666 is actually for ceramic and allied technologies. Get the fuck out of here! That is not a goof!</p>
<p>Bryan! (46:02.368)
dude, I actually I looked that up because I was like, there&#39;s no fucking I was like, what&#39;s actually in the 660s? And I was like, Oh, okay. I was like, yes, ceramics. And then I was like, Okay, so where&#39;s the occult 130s? Fuckin fantastic. Yep. So he goes. Yeah, he goes. Okay, so he finds what he&#39;s looking for while smoking.</p>
<p>Dave! (46:09.954)
Well, obviously, ceramic and allied technologies.</p>
<p>Dave! (46:20.43)
God, this movie. Jesus Christ!</p>
<p>Bryan! (46:28.908)
But the pages are torn. I don&#39;t think you were allowed to smoke indoors even in 1989.</p>
<p>Dave! (46:34.126)
I mean, you could, but not... I feel like if you&#39;re smoking this much, you might get a sideways glance, because this is excessive. I was, oh no, cause he&#39;s in the library. Is this the part where he&#39;s in the... Oh, that&#39;s later on, nevermind. We&#39;ll come back to that.</p>
<p>Bryan! (46:44.062)
Yep.</p>
<p>Bryan! (46:48.644)
No, later on the girl&#39;s like, yeah, you can&#39;t smoke in here. And he&#39;s like, huh, that&#39;s too bad. Yeah, but.</p>
<p>Dave! (46:52.174)
He&#39;s like, I&#39;m so sorry. And then he walks in and the guy&#39;s smoking a pipe.</p>
<p>Bryan! (46:55.584)
Yeah. So, he, yeah, he&#39;s, he finds what he&#39;s looking for, but the pages are torn out, but there&#39;s an author&#39;s name that he can follow up on later. So, we&#39;re gonna go there later on. But in the meantime, back at the store, the girls plan on spending the night there fooling around with some dudes. When they get</p>
<p>Dave! (47:11.978)
And I thought, can this movie get more ridiculous? And the answer is, yes, it can.</p>
<p>Bryan! (47:15.56)
Yes, yes it can and it&#39;s about to. They get all dressed up in store merchandise. Amy wears this whole like elaborate lingerie set.</p>
<p>Dave! (47:24.198)
Why is she dressed like Tawny Catane in the Here I Go Again on my own video? She has got on a flowing negligee. She has got on lingerie underneath it. This is full on sexy lingerie. And she is twirling for her friends. This is a sexy girls&#39; party. It&#39;s like a Slumber Party Massacre where they&#39;re just like, let&#39;s be sexy and get naked together. Now in Slumber Party Massacre, that is a satire.</p>
<p>Bryan! (47:29.284)
Yeah?</p>
<p>Bryan! (47:33.838)
Yeah?</p>
<p>Bryan! (47:38.016)
It is a it is like it is.</p>
<p>Bryan! (47:48.747)
Yeah.</p>
<p>Dave! (47:52.67)
and that is in there for a reason. This is serious. This is a legitimate scene in this movie.</p>
<p>Bryan! (47:57.44)
No, this, this is actually the part that made me go, Oh, this is a Jim Wynorski movie because like there&#39;s also a scene like that in evil, evil tunes. I think is this Wynorski movie, but the girls like entertain themselves.</p>
<p>Dave! (48:09.666)
So for people who don&#39;t know, Jim Winocherski made a couple of real low grade horror movies. He also made a shitload of porn.</p>
<p>Bryan! (48:17.996)
Yeah, but all like, but like all soft stuff, right? Like he wasn&#39;t a hardcore guy.</p>
<p>Dave! (48:22.098)
No, I think he has a different name than he made hardcore stuff.</p>
<p>Bryan! (48:25.152)
Oh, that would make sense. Yeah. But yeah, but I think I think it&#39;s in evil tunes where it&#39;s a similar kind of setup where it&#39;s like three girls having a sleep over and they&#39;re all going to hang out and just entertain one another and entertaining one another in one scene involves like one of them doing a strip tease for the others who are just delighted by it. So it&#39;s like, you know, girl stuff. But the way that the girls react to this outfit is so strange where they&#39;re like, no way.</p>
<p>Dave! (48:44.752)
I just...</p>
<p>Bryan! (48:51.86)
and she&#39;s offended that they&#39;re like, I mean, first of all, Amy&#39;s out to get laid. Like that&#39;s her mission for this evening. She&#39;s gonna get Dave no matter what.</p>
<p>but you know, things are things are about to take a turn but this she&#39;s going to she gets executed. I think but yeah, so so so she&#39;s wearing this and all that then the other two girls are like what the <em>**</em> are you wearing which I don&#39;t know like if if if there was somebody who was going that hard to get Dave, that&#39;s probably how you&#39;d want to send a message.</p>
<p>Dave! (49:06.338)
She&#39;s not, she&#39;s gonna get murdered. She gets murdered, 100% murdered. And so does Dave.</p>
<p>Bryan! (49:30.028)
because what Kirsten shows up in as like sexy outfit is like a wet suit and a skirt. And that to which to which Brooke is like, yes.</p>
<p>Dave! (49:37.466)
80s... the outfits are... the outfits in this movie are so triggering. They are ridiculous. It is like no... you know what? You know how people are like, ah the 80s are so cool, everything was neon and bright blah blah? No, no, this is what the 80s actually looked like. Dumb as shit and ugly as hell. Like it is horrible.</p>
<p>Bryan! (49:57.412)
The wetsuit and skirt combo, however, is like Dayglow in neon colors.</p>
<p>Dave! (50:03.39)
Well, you know, if you&#39;re going, I guess, water skiing? Sexy water skiing, but water skiing nonetheless.</p>
<p>Bryan! (50:08.765)
you. Yeah, sexy water, sexy, sexy water skiing in a Colorado department store.</p>
<p>Dave! (50:13.524)
Oh God!</p>
<p>Bryan! (50:15.368)
Yep. So meanwhile, Grizzly Adams is back in his hiding spot, having no idea that the girls are there and he spent some time reading and reading AA literature while smoking. If remarking on it. Yep. No.</p>
<p>Dave! (50:26.965)
Cuz it just gets darker.</p>
<p>He doesn&#39;t even have a regular book to read. He just has friends of Bill W.</p>
<p>Bryan! (50:38.38)
Yeah, I&#39;ll tell you what, like I spent a little time at some 12-step programs and there&#39;s a little unexpected autobiography. His vibe is very, very authentic to some of the people that I met there, where he&#39;s put together a little bit of clean time, and he&#39;s so fucking proud of himself and goddamn it, man, he&#39;s so proud of you for coming to the meetings. Keep coming, you&#39;re in the right place.</p>
<p>Dave! (50:49.335)
Oh yeah!</p>
<p>Dave! (51:01.442)
No, I know. I mean, look, I did not spend any time in 12-step programs, but I did work with homeless people for 10 years. I know a little something about it.</p>
<p>Bryan! (51:12.036)
he is he it made me think I was like now this is a guy who&#39;s been to an AA meeting. He is very he&#39;s very realistic in that in that sense. So at the same time he hears the girls and goes to see what the noise is all about, but then he all we also see that the elf is there too. So he confronts them and they realize that he&#39;s also not supposed to be there and now everybody is where they&#39;re supposed to be scary. German guys also know where everybody is.</p>
<p>And so the dudes that the girls are waiting for show up, but they eventually go away because the door is locked, but they&#39;re going to come back. And I get the feeling that there&#39;s a whole scene cut out here because the next thing we see is there&#39;s a bunch of people like banging, slamming themselves against the door. And then they burst through and it&#39;s the scary German guys, but there&#39;s a fucking</p>
<p>Dave! (52:05.708)
You skipped a very, very important moment for me in this movie.</p>
<p>Bryan! (52:10.112)
Was it this conversation that Kirsten and Brooke have? I fucking this. This was the first moment when I was like, ah, so.</p>
<p>Dave! (52:12.805)
Yes!</p>
<p>This was so dark and so terrible. And we&#39;re gonna come back to it at the end. We&#39;ll just talk about it now too, because holy shit.</p>
<p>Bryan! (52:24.321)
Yeah, so they have this nasty conversation where Kirsten tells her that she&#39;s she tells Brooke she&#39;s basically I&#39;m a virgin. I&#39;m saving myself for the right person. But when posed with the</p>
<p>Dave! (52:33.326)
To which I said, a virgin, you&#39;re 45 years old. Who are you saving yourself for?</p>
<p>Bryan! (52:36.592)
Yeah, just go with it. Just go with it. Save it herself for Hitler. But she posed with the question of how do you stop them is what Brooke asks her in the thing. Like if you know guys going for it, how do you stop him? She&#39;s just like you just say no, because they don&#39;t know how to deal with that. And Brooke says she&#39;d rather avoid a confrontation that it&#39;s easier to just go through with it. Going through with it.</p>
<p>Dave! (52:45.488)
Ugh.</p>
<p>Dave! (53:05.334)
This was so fucking sad to me, and I&#39;ll tell you why, because the movie deals with it as though it&#39;s kind of a joke. And it is not a joke, because I&#39;m sure this is a conversation that many women have probably had. And it is so goddamn gross to watch people talk about this, as though it&#39;s like, oh, you&#39;re ridiculous. Just fuck him. Oh.</p>
<p>Bryan! (53:14.67)
Yeah.</p>
<p>Bryan! (53:20.685)
Yeah.</p>
<p>Bryan! (53:28.248)
Here&#39;s the thing, at the end of this, I was like, Jeff Mandel has definitely coerced a woman into having sex. Like this is, yeah, this is terrible.</p>
<p>Dave! (53:34.21)
Oh, God, yes. There is not a single, outside of the ones that are on camera, there is not a single woman involved in this movie or in that dude&#39;s life.</p>
<p>Bryan! (53:44.96)
Yeah. Because they&#39;re all like weird teenagers or psychopath sadists. It&#39;s a mess. It&#39;s a fucking mess. Yep.</p>
<p>Dave! (53:54.462)
Yeah. Yeah, it is the bitch mom, the fucking prude teenage virgin, and these sluts. That is what this guy thinks of women. And at no time does anybody be like, hey, man, that&#39;s a little bit fucked. Because you know what? That&#39;s how a lot of men thought of women in the 80s and today.</p>
<p>Bryan! (54:02.316)
Yeah. It cures the cure.</p>
<p>Bryan! (54:14.688)
Yeah. I don&#39;t like it. I don&#39;t like it. Kirsten gives her the same gives her the same troubled look that I would give her. Meanwhile, the scary German guys forced their way into the building. It&#39;s also dead body. It&#39;s not really clear who it&#39;s supposed to be. I suspect that there is a scene where the scary German guys interrupt the dudes trying to get in and they end up killing Dave or one of them. And so we don&#39;t we don&#39;t see his face. We never see Dave&#39;s face either.</p>
<p>Dave! (54:19.403)
Nope.</p>
<p>Bryan! (54:42.424)
We just see these three guys and none of them are really ever identified. Yeah. And so I think, I think Dave gets killed. Thank God.</p>
<p>Dave! (54:45.89)
Yeah, they&#39;re just dudes in shadow.</p>
<p>Bryan! (54:54.024)
Amy, excited to finally get laid, runs to the elevator to meet Dave and ends up cornered there by the German guys who execute her. Then Grizzly Adams leaps into action, sneaking around the place.</p>
<p>Dave! (55:05.43)
Yeah, but my favorite part is that he&#39;s sneaking, but also smoking. He may as well be whistling at the same time.</p>
<p>Bryan! (55:09.322)
I&#39;m so, so smokin&#39;.</p>
<p>Bryan! (55:16.169)
the</p>
<p>Dave! (55:17.166)
Yeah.</p>
<p>Dave! (55:21.622)
waving incense around as he walks, like, what the fu-</p>
<p>Bryan! (55:26.688)
And that thing is, he...</p>
<p>Dave! (55:27.21)
This movie is such garbage!</p>
<p>Bryan! (55:33.104)
He is, he is, he is.</p>
<p>Dave! (55:33.515)
I can&#39;t.</p>
<p>Dave! (55:37.594)
I had to stop it like three times. Every single time, I was like, Jesus Christ, why is there 40 minutes left? How is there 40 minutes left?</p>
<p>Bryan! (55:47.648)
the same thoughts this is the this is kind of the middle of the movie that we&#39;re at and so the thing oh no</p>
<p>Dave! (55:54.206)
And there are not a lot of elves in this movie, elves.</p>
<p>Bryan! (56:02.352)
Kirsten, she sees the elf also there and becomes kind of frightened. And so now begins the world&#39;s slowest shootout. Grizzly Adams.</p>
<p>Dave! (56:12.374)
And this is where 90% of the budget went.</p>
<p>Bryan! (56:15.828)
Oh my god, they wasted so much money on blanks. Grizzly Adams meets up with Kirsten when he finds her hiding in the gun section of the store. And then Brooke makes a break for it, finds Amy&#39;s dead body in the elevator before hiding out in the section of the store where all the creepy mannequin parts are. And then the elf chases her. And she fucking like falls down the steps twice, trying to run from him. But he eventually gets her, and while wearing a Santa hat, he slashes her up and kills her.</p>
<p>So eventually they&#39;re.</p>
<p>Dave! (56:48.578)
Which, I mean, I guess points for whimsy.</p>
<p>Bryan! (56:54.554)
But while this is all happening, there is, I swear to God, this shootout accounts for like 15 minutes of the movie. And it&#39;s just Grizzly Adams and Kirsten behind one display, occasionally like reaching up and like shooting their guns and then three guys who look like extras from a lethal weapon movie shooting back.</p>
<p>Dave! (57:16.47)
Yeah, this is essentially the opening scene of Puppet Master. Like when the guys, the Nazis sneak into the hotel.</p>
<p>Bryan! (57:23.764)
Yeah. But I mean, yeah, they got that. They got that whole like slicked back hair, but it&#39;s also like pulled into a ponytail kind of thing.</p>
<p>Dave! (57:31.798)
Yeah, this is what you call a pastiche pill.</p>
<p>Bryan! (57:35.18)
Yeah, like it&#39;s like Charlie Kelly and the gang makes lethal weapon seven like that it&#39;s always Sony episode. They basically they shoot at each other and nobody gets shot. And then eventually police sirens are heard and they all take off and when the cops show up grizzly Adams tells them that an elf killed the girls, but that he didn&#39;t but he but also he didn&#39;t see the elf. I don&#39;t know where he got this in from.</p>
<p>Dave! (57:39.962)
Ugh.</p>
<p>Dave! (58:01.67)
No, but he just knows about it. Because he remembers a book that he read when he was in college. And he went, god damn. He knows a thing or two. He&#39;s a bit of an aficionado.</p>
<p>Bryan! (58:06.224)
Ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha</p>
<p>Bryan! (58:11.104)
Yeah, that&#39;s true. Studies the occult in his spare time. Yeah, he saw scary German guys, but he never makes mention of them or the fact that they shot the place up. He just talks about the elf which kind of kind of got kind of got me. So grizzly Adams takes Kirsten home and he attempts to explain to evil mom that a creature is trying to kill her, but her mom insists that she was just out there in the store banging grizzly Adams.</p>
<p>Dave! (58:14.754)
It was time at Marlboro University.</p>
<p>Bryan! (58:40.736)
Meanwhile, Grig, I mean sure, that fucking beard. He is a bear of a man too. Like he is a, he, he gives the impression that he is a, he is a tall, heavy guy. Yep. Grandfather is very, very interested in this creature. So, Grizzly takes off. He heads over to the university to talk to the</p>
<p>Dave! (58:41.166)
I mean, I get it.</p>
<p>Dave! (58:46.69)
He&#39;s very large.</p>
<p>Dave! (59:02.07)
Well, Grizzly takes off because the mother says, I&#39;m calling the police. And then she grabs the phone and dials, I don&#39;t know what number, and then she goes, 911, thank you.</p>
<p>Bryan! (59:14.08)
She called the operator. Yeah, yeah. Grizzly heads over to the university to talk to the professor from the book that and grand grandfather resolves to get Kirsten away from scary German guys in the elf. So but</p>
<p>Dave! (59:14.895)
And, scene!</p>
<p>Dave! (59:27.594)
This is the part where he walks into the library and the girl&#39;s like, you can&#39;t smoke in here. And even the girl at the library is an asshole. Everybody in this movie sucks.</p>
<p>Bryan! (59:32.534)
Yup.</p>
<p>Yeah, this is true. Everybody is the fucking worst. The professor is... He&#39;s a professor.</p>
<p>Dave! (59:44.462)
It&#39;s hard to explain what, cause I, so my note says, is he doing camp? I don&#39;t think so. I don&#39;t think it&#39;s intentional, but he is doing it.</p>
<p>Bryan! (59:51.013)
Yes. Or just I can&#39;t.</p>
<p>Bryan! (59:56.836)
like he thinks that like he thinks that this is what a professor is like. He&#39;s he&#39;s</p>
<p>Dave! (01:00:00.534)
Yeah, at one point he goes, the little creepy, the creepy creatures on two legs, idiomatically we call them elves. No we don&#39;t. Nobody calls those elves. We all know what elves are and it&#39;s not that.</p>
<p>Bryan! (01:00:08.497)
Yeah. Yeah, this guy is.</p>
<p>this guy is C3PO smoking a pipe basically. He he&#39;s ridiculous. He&#39;s can&#39;t be as hell. He&#39;s in the wrong movie. But he says he really is because the next professor fucking sucks too. He tells Grizzly Adams that the symbol represents the elves and that the Nazis believed in these elves and he has no idea what the Nazis would be doing with the elves.</p>
<p>Dave! (01:00:28.002)
But he is the only likable person in the entire f-</p>
<p>Bryan! (01:00:43.364)
but he knows a dude who does and he directs Grizzly to that guy. But meanwhile, Grizzly&#39;s being be entailed by scary German guys. Now, back at home, grandfather does some sort of magic shit with the crystals he was given by the Nazis earlier. Well, Evil Mom... That&#39;s true. That&#39;s true.</p>
<p>Dave! (01:01:00.034)
Well, he&#39;s reading by candlelight when we cut back to him. It&#39;s very strange. Surrounded by all of his tchotchkes from things remember-</p>
<p>Bryan! (01:01:08.212)
Yep. And so Evil Mom tells Kirsten that the police confirmed her story about the store, but probably not parts about the elf. And now.</p>
<p>Dave! (01:01:17.794)
Well, now we&#39;ve got to take a break for a moment here to talk about the outfits. Because mom&#39;s shirt, no, we should say, Kirsten&#39;s mom doesn&#39;t have a name. She is just Kirsten&#39;s mother. Even on IMDB, she&#39;s just Kirsten&#39;s mother. Now, she has got a shirt that has a ruffle on it. That is, it looks, it&#39;s very Pirates of Penzance. It&#39;s big. There&#39;s a lot happening.</p>
<p>Bryan! (01:01:33.337)
Yeah.</p>
<p>Bryan! (01:01:39.01)
It&#39;s f-</p>
<p>Bryan! (01:01:43.551)
It&#39;s 15 feet of cloth easily. Yep.</p>
<p>Dave! (01:01:45.842)
Yeah. Now, Kirsten, on the other hand, she is wearing a gold vest that makes her look like a movie theater usher. She looks like, she&#39;s like the girl from Demons, the usher from Demons.</p>
<p>Bryan! (01:01:53.792)
I think those are her-</p>
<p>Bryan! (01:01:59.395)
Oh, the girl in the green dress. No, I think those are supposed to be her work clothes.</p>
<p>Dave! (01:02:00.755)
Mm-hmm.</p>
<p>Dave! (01:02:05.422)
Well, that&#39;s less fun.</p>
<p>Bryan! (01:02:07.12)
I know. Let&#39;s see. So for a moment, Evil Mom appears to soften towards her and then gets a bit sentimental at the mention of Kirsten&#39;s father, but throw all that shit out because we&#39;re about to learn all about her father and why her mom is such a fucking monster.</p>
<p>Dave! (01:02:23.806)
Holy shit.</p>
<p>Bryan! (01:02:25.432)
Kirsten thinks that her dad died, but her mother tells her that if she wants to talk to her father so badly, she could go downstairs and do it because he&#39;s in the study where grandfather is. You putting this together yet?</p>
<p>Dave! (01:02:37.058)
That&#39;s right. Yup, she&#39;s my daughter and my sister.</p>
<p>Bryan! (01:02:43.035)
grandfather is her father and her grandfather.</p>
<p>Dave! (01:02:47.815)
Ugh, why is there so much incest in this movie?</p>
<p>Bryan! (01:02:49.004)
Ah, so, so when that when I first watched this at that moment, I stood up and went gross. Like I could not believe that this that a movie that&#39;s already just rock bottom is digging even deeper. Yeah, so</p>
<p>Now, now there&#39;s a bit of a bit of a scuffle. Evil mom strikes Kirsten while the elf watches from the window, thus sealing her fate. Now, at the other professor&#39;s house, Grizzly Adams bursts in on Christmas Eve dinner and Grizzly is breathing heavily throughout the entire scene. Probably because yeah, because he&#39;s been smoking unfiltered camels throughout the entire movie.</p>
<p>Dave! (01:03:30.289)
Well, he did have to go up a set of eight stairs, so...</p>
<p>Dave! (01:03:36.714)
He usually takes the elevator everywhere he goes.</p>
<p>Bryan! (01:03:43.056)
I think he must it must not I can&#39;t tell if it was deliberate like they were like, okay, so like you&#39;re in a hurry you&#39;re excited like give us a little heavy breathing and he was like no sweat I got this.</p>
<p>Dave! (01:03:53.494)
No, that&#39;s just how he breathes most of the time.</p>
<p>Bryan! (01:03:58.088)
So the professor tells him that the Nazis wanted to use the elves as assassins, but there&#39;s a problem with that theory because elves are not real. You fool. The other theory is that the master race genes were somehow put into these elves, and if the elves mate with a virgin on Christmas Eve, they&#39;ll produce the master race as one does.</p>
<p>Dave! (01:04:08.698)
Fair. A fair thing to say.</p>
<p>Dave! (01:04:22.178)
he&#39;s like, listen, uh, there&#39;s no problem here, because elves aren&#39;t real dummy, but you know what, if they were, okay, if they were, here&#39;s a second theory.</p>
<p>Bryan! (01:04:30.724)
Hahaha</p>
<p>Yep. So great back home. Grandfather explains to Kirsten</p>
<p>Dave! (01:04:38.259)
Oh, it gets worse, it just gets worse.</p>
<p>Bryan! (01:04:41.136)
I don&#39;t have the specifics, but the moment when he was like, I drugged her, she was unconscious. I was like, I was like, oh God, why is this just getting worse? So he</p>
<p>Dave! (01:04:45.403)
Oh, I do. I do.</p>
<p>Dave! (01:04:50.058)
Here you go, it goes like this. She was drunk and unconscious. I took no pleasure in it. My note says, dear God, stop talking about it.</p>
<p>Bryan! (01:05:01.118)
Yeah, he did it because he was on a mission and now he regrets it. Some kind of sex magic nonsense about perfect genes and inbreeding. None of it makes sense. Nothing matters. It&#39;s just it&#39;s all gross. It&#39;s all fucking disgusting.</p>
<p>Dave! (01:05:09.066)
It doesn&#39;t matter, none of this matters ever anymore ever again. Nothing matters anymore.</p>
<p>Dave! (01:05:19.294)
And from here on out, this shit gets so weird. The movie just goes off the fucking rails.</p>
<p>Bryan! (01:05:23.245)
Yeah, so.</p>
<p>This yeah, we are rounding the corner. Evil mom&#39;s getting ready to take a bath with the fucking rate.</p>
<p>Dave! (01:05:31.786)
Now, I have a couple questions. Why is she putting on her lipstick like fucking Buffalo Bill? Like, she has got that, she is like Baby Jane Hudson, just like fucking one fist, she is jamming that shit on her mouth.</p>
<p>Bryan! (01:05:46.56)
Oh, the direction was clearly like, okay, so like you&#39;re over the edge now. You&#39;re going crazy because she starts out just like dabbing it on gingerly. And then we cut away and there&#39;s more gross ship between Kirsten grandfather. But we come back and she&#39;s like fully like whole like. She&#39;s smearing it on her face, like that scene in Night of the Demons. At yeah, and then and then we cut away from that.</p>
<p>Dave! (01:06:00.783)
She looks like the fucking Joker.</p>
<p>Dave! (01:06:07.664)
Oh.</p>
<p>Dave! (01:06:11.47)
She&#39;s like a knockoff Cloris Leachman, and she&#39;s just getting ready for the bath. I love this part so much.</p>
<p>Bryan! (01:06:14.925)
Yeah.</p>
<p>Bryan! (01:06:18.736)
they but they and there&#39;s a radio right next to the tub like a fucking rube but we&#39;re</p>
<p>Dave! (01:06:23.17)
But like this is, so this is 1989. That radio looks like an eight track cassette player from like 1964.</p>
<p>Bryan! (01:06:31.052)
I guarantee like they were like, Ah, shit, we need a radio and grizzly Adams was like, Listen, listen, I got one out in my car, I&#39;ll be right back. And so grizzly Adams, meanwhile, is racing back to Kirsten&#39;s house with this information about the elves when he reaches for the cigarette lighter, like cars used to have, and he accidentally knocks loose an old timey bundle of dynamite that scary German guys stashed in his car. So then a stuntman</p>
<p>Dave! (01:06:33.678)
Just in the house.</p>
<p>Dave! (01:06:57.174)
This is some, yeah, it&#39;s like some real mustache twirly shit.</p>
<p>Bryan! (01:07:00.392)
A stuntman dives from the car as it slowly rolls along before it explodes. And then when the scary German guy shows up to make sure that Grizzly Adams is dead, Grizzly attacks him and beats him while asking, Where&#39;s Kristen? What are you some kind of Nazi? Is that elf yours? Now, here&#39;s the thing. I read that flat. I read that flat from the page. That is exactly how he delivers the</p>
<p>Dave! (01:07:21.07)
I love all of those questions.</p>
<p>Yep. I was actually going to say, you said, where is Kristen? And I want to point out that that&#39;s not a mistake. He says, where is Kristen? They call her Kirsten and Kristen from here on out.</p>
<p>Bryan! (01:07:34.224)
the answer is. Yeah, it&#39;s interchangeable. Yeah. But before he can get his</p>
<p>Dave! (01:07:43.75)
One take! We&#39;ve got one take! Nobody calls cut! Just go!</p>
<p>Dave! (01:07:50.018)
That&#39;s the Action International Pictures way.</p>
<p>Bryan! (01:07:52.976)
That&#39;s a it&#39;s high quality that you can expect from action international pictures. Do take cyanide or something and he kills himself. Grizzly can&#39;t believe that he&#39;s in this movie. He says something like, this is a fucking circus. And I guarantee that was just something that he was he was just thinking out loud about his role in this movie. And they were like, Hey, that&#39;s a great line. Let&#39;s keep that.</p>
<p>Dave! (01:08:15.126)
Oh yeah, no, there&#39;s no script anymore. That&#39;s it, we&#39;re done with script.</p>
<p>Bryan! (01:08:19.825)
Yeah. Back at home, mom is rubbing a towel all over her face like a crazy person while she&#39;s in the tub. And this is when the elf pushes the radio into the tub while it plays ode to joy, electrocuting a pretty obvious body double. And nothing of value was lost.</p>
<p>Dave! (01:08:35.379)
Also, when you throw a radio into the bathtub, it does not play anymore. It will not continue to play.</p>
<p>Bryan! (01:08:43.354)
very true, very true, but that wouldn&#39;t be that&#39;s not it wouldn&#39;t be very funny though.</p>
<p>Dave! (01:08:44.394)
That is not how electronics work at all.</p>
<p>No, but you know what is funny? The elf at the end of the tub rocking back and forth as though he is laughing maniacally, except it is just a solid piece of rubber, so it can&#39;t laugh. So they&#39;re just sort of shaking it on a stick.</p>
<p>Bryan! (01:08:54.15)
Hahaha!</p>
<p>Bryan! (01:09:06.228)
So, yeah, God. Now, now, Grizzly.</p>
<p>Dave! (01:09:08.866)
Listen, I&#39;m sorry everybody, this episode is probably fucking terrible. Listen to... it is 60% of us just laughing and talking about incest, and it sucks.</p>
<p>Bryan! (01:09:25.008)
regret nothing. So, Grizzly gets back and the house is dark. He with the children goes upstairs and he finds the body of the of evil mom and that symbol is smeared on the tub in blood. Then they go back downstairs and he confronts grandfather. Grizzly shows him the symbol and we learn that that&#39;s the original symbol that the swastika replaced and that the whole point of the elf program was that the virgin</p>
<p>Dave! (01:09:25.302)
My condolences.</p>
<p>Bryan! (01:09:54.8)
will produce the Antichrist because why the <em>**</em> not at this point? And hey, remember that drawing with the Artneko boobs? If you connect, if you connect the lines of those Artneko lines, they make swastika boobs. It&#39;s all coming together, right?</p>
<p>Dave! (01:10:05.09)
We&#39;re gonna bring that one back.</p>
<p>Dave! (01:10:12.906)
Again, a 12 year old boy wrote this movie. And then I&#39;m gonna draw in her boobs and it&#39;s gonna be real sweet.</p>
<p>Bryan! (01:10:19.396)
Ha ha!</p>
<p>Dave! (01:10:21.575)
Damn it.</p>
<p>Bryan! (01:10:25.834)
Oh no! So...</p>
<p>Bryan! (01:10:33.069)
grandfather.</p>
<p>Dave! (01:10:33.253)
Oh!</p>
<p>Bryan! (01:10:44.221)
Ugh.</p>
<p>Dave! (01:10:55.902)
It&#39;s, uh... It&#39;s snoozy!</p>
<p>Dave! (01:11:01.134)
Ugh. Cough.</p>
<p>Bryan! (01:11:01.516)
gotta compose I guess gotta compose myself I&#39;m leaving this all in so okay</p>
<p>Dave! (01:11:07.028)
I can&#39;t, why not? Nobody&#39;s made it this far.</p>
<p>Bryan! (01:11:11.62)
So grandfather also tells us that they could kill the elf with their magical elf stone crystal from before, but the Nazis show up before he can tell her how to use it. Grizzly Adams fights the Nazis in a really stupid fight in Suisse where grandfather is executed by the lead Nazi, but his goon dies from some gunshot wounds, but before he dies, he shoots Grizzly.</p>
<p>Dave! (01:11:38.986)
Now, none of this matters because you can&#39;t actually see anything that is going on in the room because it is pitch black.</p>
<p>Bryan! (01:11:42.656)
No, and here&#39;s the best thing. The sound mix guy just plain forgot to add a gunshot sound to the audio track. So what you see is Grizzly struggling with a guy and then the dude turns over and he makes a motion like he&#39;s shooting the gun, but there&#39;s no sound. And then Grizzly Adams just rolls over presumably dead.</p>
<p>Dave! (01:12:04.558)
I would not have been surprised if you just heard someone go, pew pew! And then that was it. Nothing matters anymore.</p>
<p>Bryan! (01:12:12.312)
No, no. And you can tell that like all the all this is all like live audio mix like there was no dubbing. There was no ADR because all during that really fucking bullshit gun gunfight. All the sounds is are the blanks going off, which is why the guns all sound so unimpressive. So the elf. Let&#39;s see. No, no, no. I&#39;m getting ahead of myself. Kirsten and a brother run out into the woods to escape, but the lead Nazi finds them and he tries to</p>
<p>Dave! (01:12:38.338)
Now here&#39;s my question though, why did the- so the brother, he&#39;s introduced at the beginning, he&#39;s a little shithead who&#39;s a total fucking creep, and that&#39;s about it. Why did they feel the need to bring him back at the end, as though adding a little boy to the mix is just like, raise the stakes? I don&#39;t give a shit if this kid gets killed, I actually hope that he does!</p>
<p>Bryan! (01:12:56.224)
I have, yeah, I have no idea. I have no idea. There&#39;s not a whole lot of thought in this movie. Like he could have been removed from the movie entirely and it&#39;d be the same movie. So they go out. He finds him. He tries to prepare her for her date with destiny but the elf shows up with the gun from the previous fight and he shoots him. Yeah and so the elf. Yeah, let&#39;s not get let&#39;s not get carried away.</p>
<p>Dave! (01:13:16.91)
Because the elves are using guns now. I&#39;m sorry the elf there&#39;s one elf This movie should have been called the elf</p>
<p>Bryan! (01:13:25.984)
So, she sends her brother back to the house to get the crystal. Now, meanwhile, the elf is ready to knock boots with Kristen, Kirsten. No, no, because they really take their time with this scene. She fights it and then, you know, her brother, we cut back, he&#39;s back in the house, he&#39;s looking for the for the crystal and the grizzly atoms like lurches into the scene real suddenly like obviously feigning injury and he&#39;s like, here kid, here&#39;s that crystal, you need to kill the elf.</p>
<p>Dave! (01:13:34.723)
I did not like this at all.</p>
<p>Dave! (01:13:55.038)
Meanwhile, the elf is backed by the, I don&#39;t know, waterfront or whatever, and he just grabs a bug and eats a bug just to be fucking gross or something.</p>
<p>Bryan! (01:14:03.076)
Oh yeah, yeah. But he also like he also like offers it to her because there&#39;s been a couple of scenes throughout this where like the elf eats a bug. And then whatever happens next is anybody&#39;s guess. They&#39;re like the elf&#39;s hand is like moving up her leg and like lifting up her skirt and shit. And she&#39;s looking at him very nervously. And then the brother comes back and he gives her the crystal. And she&#39;s like, I</p>
<p>grandfather said I&#39;d know what to do with this when the time came, which is some fucking bullshit, and she crawls along the ground next to the elf who seems to be in retreat, and then she stabs the ground with the crystal where the, I think where the elf came out of the earth originally, and the earth and the elf explodes into a shower of sparks before melting into a pile of goo amid a psychedelic video freakout.</p>
<p>And now we cut to morning and it&#39;s snowing and we zoom out slowly amidst a synthesizer piano piece, fade to black, roll credits over an evil looking glowing fetus.</p>
<p>Dave! (01:15:12.222)
And everything is OK. Now, I have two points I want to make. The first is that, everybody, if you ever think you cannot live your dreams, I want to remind you of two things. One, I make a living writing a very successful podcast. Two, somebody made this movie, got it into movie theaters.</p>
<p>Bryan! (01:15:16.83)
Elves!</p>
<p>Dave! (01:15:41.078)
got it onto home video, and we are talking about it today 30 years later. We can all do the things we want to do.</p>
<p>Bryan! (01:15:47.059)
Hahaha</p>
<p>Bryan! (01:15:52.024)
Believe in yourself, you too can make elves.</p>
<p>Dave! (01:15:56.29)
Fuck this movie. A lot. This is fucking terrible.</p>
<p>Bryan! (01:15:56.66)
It&#39;s... Hahaha!</p>
<p>Bryan! (01:16:02.092)
Uh it&#39;s uh you know here&#39;s the thing is I don&#39;t I don&#39;t think I mean this movie definitely did not get a theatrical release. No because the thing is probably because it looks it looks like it was shot on video like high quality studio video cameras definitely not filmed.</p>
<p>Dave! (01:16:09.266)
Oh, yes, it did. It got a limited theatrical release. I&#39;m going to guess in Colorado.</p>
<p>Dave! (01:16:20.41)
It, I, but I, yeah, it definitely feels like a Shauna, like a fucking SOV classic, and yet it did.</p>
<p>Bryan! (01:16:31.288)
That&#39;s fucking nuts. It is unbelievable garbage. Unbelievable. It is maddening to watch. It is, the acting is strange. The story is nonsensical and extremely offensive. There&#39;s no redeeming values in it whatsoever. And yet I have watched this movie three times this year, and I laugh my ass off every time.</p>
<p>Dave! (01:16:47.522)
So...</p>
<p>Dave! (01:16:56.216)
I&#39;m gonna...</p>
<p>I&#39;m gonna do it anyway. So we revisit these movies to talk about them and see how they play out these days. Now, neither of us saw this in 1989. You saw it last year. Yeah, I saw it just yesterday, regrettably. And so what I&#39;m gonna ask anyway, assuming we had seen this, put yourself in 1989. You had a sweet Guns N&#39; Roses denim jacket.</p>
<p>Bryan! (01:17:08.98)
Oh yeah, I did not even know about this movie until this year.</p>
<p>Bryan! (01:17:14.8)
Hehehehehehe</p>
<p>Bryan! (01:17:25.796)
I did.</p>
<p>Dave! (01:17:27.514)
You see this movie and you think it&#39;s real awesome because there&#39;s swastikas on boobs. Well, here we are now, 400 years later. What do you think of this movie now?</p>
<p>Bryan! (01:17:38.624)
Uh it&#39;s I think I&#39;ve made it pretty clear that it is a work of insanity that it is it was made by people. The the message that manages to seep through the garbage unintentionally is that the people who made this movie were probably rapists.</p>
<p>But goddamn, that puppet is ridiculous. And this plot is like made up on the fly. And I would probably watch this movie again, probably not for a little while, but I could definitely see myself going to somebody I know, hey, have you seen elves? Oh no? Allow me to introduce you to the most fucked up movie you&#39;ve ever seen.</p>
<p>Dave! (01:18:26.962)
I am never going to watch this movie again, and nor do I recommend that anybody else watch it. I&#39;ll tell you what, this movie is cheap and fucking terrible, it is cynical as hell, and this has nothing to offer, even the most desperate horror event. Like that scene where they&#39;re talking about not wanting to have sex is one of the bleakest things I&#39;ve ever seen because it&#39;s so fucking real, and yet everybody in this movie is shitty and terrible, and the whole thing is treated like it&#39;s either normal or hilarious.</p>
<p>Bryan! (01:18:55.112)
Yep. It&#39;s it&#39;s such a fucking weird, real moment that really bothers me. I don&#39;t care for it at all. It&#39;s definitely the moment when I was like, Oh, these guys suck. But still.</p>
<p>Dave! (01:18:55.489)
is disgusting.</p>
<p>Dave! (01:19:09.354)
Yeah, this represents all the worst things about like 80s horror, and it treats them as though they&#39;re all legitimate and not just like the perspective of shitty young men. It is so fucking dark. And yeah, I mean, it was hilarious to talk about it. This was a real fucking slog to get through. I shut this movie off. I almost shut it off and like, was gonna restart it later or whatever. Like, there&#39;s a point where you can tell I just stopped taking notes because it just, it&#39;s so fucking bad.</p>
<p>Bryan! (01:19:30.288)
Hahaha!</p>
<p>Bryan! (01:19:38.448)
I don&#39;t, I think is I don&#39;t even think it&#39;s 90 minutes. I think it runs with, I think with credits, I think it runs about 82 minutes. So, you know, that&#39;s, there it is, Elves. 82 minutes of absolute mind blasting craziness. So let&#39;s put this one behind us. Let&#39;s get this one in the past. What&#39;s next?</p>
<p>Dave! (01:19:51.15)
Goddamn.</p>
<p>Dave! (01:19:56.362)
Ugh.</p>
<p>Dave! (01:20:00.794)
Well, OK. That&#39;s good that we&#39;re going to put this one in the past. I will tell you what, though. From here on out, from here until the end of the year, we&#39;ve got some fucking bangers of movies we&#39;re going to be watching. We&#39;ve got a couple of really great guests. We&#39;ve got a thing we&#39;re going to announce a little bit later that&#39;s really fun and exciting. So I&#39;m thinking this was like we got this over the hill. We made it over the hill. So next up.</p>
<p>Bryan! (01:20:12.18)
Oh yeah, it&#39;s wall to wall hits. I&#39;m looking forward to the rest of these.</p>
<p>Dave! (01:20:29.066)
two weeks we&#39;re coming back we&#39;re gonna be talking about that old thanksgiving classic blood rage and if you think that i am not going to be doing louise lasser impressions you are very wrong because i will be doing it the whole time</p>
<p>Bryan! (01:20:46.884)
Yeah, it&#39;s an emergency. Yep. You can&#39;t leave me like this. It&#39;s an emergency. That&#39;s not that&#39;s not cranberry sauce. That&#39;s not cranberry sauce. Oh, God. All right. All right. I&#39;m so sorry, everybody. We&#39;ll see you in two weeks with blood rage.</p>
<p>Dave! (01:20:49.367)
Operator!</p>
<p>Dave! (01:20:53.711)
Oh, that movie.</p>
<p>Dave! (01:21:04.39)
Ugh.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/the-watcher-in-the-woods">Next Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)